import { Typography, makeStyles } from "@material-ui/core";
import playButton from "../../assets/images/play_circle.png";
import codeOfConductThumb from "../../assets/images/yourBenefitsPackageThumbnail.png";
import { useAppState } from "../../state/state";
import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({

  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "20px",
    marginLeft: "20px"
  },
  textContainter: {
    maxWidth: 628,
    marginLeft: "auto",
    marginRight: 30,
    textAlign: "start"
  },
  title: {
    fontSize: 42,
    fontWeight: "bold",
    color: "#ff1a68",
    marginBottom: 10
  },
  description: {
    fontSize: 14,
    fontWeight: 500,
    color: "#f4f4f4",
    marginBottom: 14
  },
  contentContainer: {
    display: "flex",
    alignItems: "center"
  },
  videoBox: {
    display: "grid"
  },
  thumbnail: {
    // width: 580,
    height: 349,
    border: "solid 2px #fff",
    gridArea: "1 / 1"
  },
  playButton: {
    gridArea: "1 / 1",
    margin: "auto",
    width: 104,
    height: 104,
    cursor: "pointer",
    "&:hover": {
      width: 80,
      height: 80
    }
  }
}));

export const YourBenifitsPackage = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("sections");
  const { setVideoSrc } = useAppState();
  const { setIsPlaying } = useNarrationContext();
  const onPlayButtonClick = () => {
    setIsPlaying(false);
    setVideoSrc(t("content.your_benefits_package.video_link"));
  };


  return (
    <div className={classes.container}>
      <div className={classes.textContainter}>
        <Typography className={classes.title}>{t("content.your_benefits_package.title")}</Typography>
        <Typography className={classes.description}>{t("content.your_benefits_package.description_1")}</Typography>
        <Typography className={classes.description}>{t("content.your_benefits_package.description_2")}</Typography>
        <Typography className={classes.description}>{t("content.your_benefits_package.description_3")}</Typography>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.videoBox}>
          <img className={classes.thumbnail} src={codeOfConductThumb} />
          <img className={classes.playButton} src={playButton} onClick={onPlayButtonClick} />
        </div>
      </div>
    </div>
  );
};
