
export const ApiRequestUrl = {
  BASE: (process.env.REACT_APP_API_BASE_URL)
    ? process.env.REACT_APP_API_BASE_URL : "https://dev.xr-cms.olivegroup.io/api",
  // ADMIN
  GET_LEARNERS: "admin/users/list",
  DELETE_USERS: "admin/users/delete-many",
  LEARNER_BULK_UPLOAD: "admin/users/bulk-upload",
  CENTRAL_PASSWORD_LOGS: "admin/central-password/logs",
  CENTRAL_PASSWORD_UPDATE: "admin/central-password/update",
  // SYSTEM
  GET_SYSTEM_SETTING: "system-settings/get",
  CREATE_SYSTEM_SETTING: "system-settings/create",
  // AUTH
  SIGN_IN: "auth/signin",
  LEARNER_SIGN_UP: "auth/signup",
  FORGOT_PASSWORD: "auth/forgot-password",
  RESET_PASSWORD: "auth/reset-password/",
  VERIFY_EMAIL: "auth/verify-email/",
  // SECTIONS
  COMPLETED_SECTION: "api/section_completed",
  GET_COURSE_PROGRESS: "course-progress/get",
  COURSE_PROGRESS: "course-progress/upsert",
  GET_SECTIONS: "section/get",
  GET_QUESTION_FORMS: "question-form/get",
  GET_SECTION_PROGRESS: "section-progress/get",
  SECTION_PROGRESS: "section-progress/upsert",
  // TRANSLATIONS
  GET_SECTIONS_CONTENT: "translation/get/sections/",
  GET_UI_CONTENT: "translation/get/ui/",
  GET_QUIZZES_CONTENT: "translation/get/quizzes/",
  GET_UNITY_CONTENT: "translation/get/unity/",
  GET_ALL_CONTENT: "translation/get/all"
};

export const ApiErrorStatusCode = {
  BAD_REQUEST: 400,
  UNAUTHORIZEDS: 401,
  NOT_FOUND: 404,
  INTERNAL_STATUS_ERROR: 500
};
