import { Link, useHistory, useParams } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import BackIcon from "../../components/Icons/BackIcon";
import OcadoLogo from "../../components/Icons/OcadoLogo";
import OcadoLogoLarge from "../../components/Icons/OcadoLogoLarge";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { apiResetPassword } from "../../api/auth";
import { success } from "../../utils/toast";
import { useForm } from "react-hook-form";

const UpdatePassword = () => {
  const history = useHistory();
  interface updatePassword {
    password: string;
    confirm_password: string;
    token: string;
    userId: string;
  }
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<updatePassword>();

  type viewParams = {
    userId: string;
    token: string;
  };
  const { userId, token } = useParams<viewParams>();

  const onSubmit = handleSubmit((data: updatePassword) => {
    data.userId = userId;
    data.token = token;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    apiResetPassword(data).then((responseData: any) => {
      if (responseData.status === "success") {
        success(responseData.data);
        history.push("/success-password");
      }
    });
  });

  const useStyles = makeStyles(() => ({
    errorMessage: {
      color: "red",
      display: "flex",
      alignItems: "center",
      margin: "1em 0 0.2em",
      "& svg": {
        marginRight: "0.4em"
      }
    },
    languageDropdown: {
      position: "absolute",
      left: 49,
      top: 54
    },
    ocadoLogo: {
      position: "absolute",
      right: 10,
      top: 25
    },
    loginContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      zIndex: 3,
      paddingTop: 186
    },
    subtitle: {
      fontSize: 17,
      fontWeight: "bold",
      color: "#4a4e4e",
      marginBottom: 7
    },
    passcodeContainer: {
      width: 454,
      minHeight: 335,
      padding: "37px 34px 37px",
      marginTop: 50,
      marginBottom: 35,
      border: "solid 2px #979797",
      backgroundImage: "linear-gradient(to bottom, #012749 18%, #061727 100%)",
      zIndex: 13
    },
    textBox: {
      display: "flex",
      flexDirection: "row",
      maxWidth: 456,
      marginTop: 25,
      marginBottom: 25
    },
    icon: {
      marginRight: 17,
      maxWidth: 43,
      height: "100%"
    },
    noteText: {
      color: "#4a4e4e",
      fontSize: 16
    },
    loginButton: {
      width: "100%",
      marginTop: 15,
      height: 56,
      borderRadius: 8,
      color: "#ffffff",
      fontSize: 17.5
    },
    forget4Text: {
      color: "#1b1c21",
      fontSize: 24,
      marginTop: 0,
      marginBottom: 8
    },
    forgetPText: {
      color: "#9298a3",
      fontSize: 16,
      marginTop: 0,
      marginBottom: 20
    },
    formControlWrap: {
      marginBottom: 20
    },
    forgetLoginFooter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 40
    },
    commonBorderBtn: {
      display: "flex",
      alignItems: "center",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 20,
      paddingLeft: 20,
      fontSize: 18,
      color: "#13171f",
      border: "1px solid #13171f",
      borderRadius: 6,
      textDecoration: "none",
      cursor: "pointer"
    },
    forgetBackImg: {
      marginRight: 8,
      width: 20,
      height: 20
    },
    commonBgBtn: {
      display: "flex",
      alignItems: "center",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 20,
      paddingLeft: 20,
      fontSize: 18,
      color: "#fff",
      border: "1px solid #7241f0",
      background: "#7241f0",
      borderRadius: 6,
      textDecoration: "none",
      cursor: "pointer"
    },
    forgetFrontImg: {
      marginLeft: 8,
      width: 20,
      height: 20
    },
    forgetErorMsg: {
      display: "flex",
      alignItems: "center",
      marginTop: 15
    },
    forgetErorMsgText: {
      fontSize: 16,
      marginLeft: 8,
      color: "#f46582"
    },
    updatePasswordMsg: {
      fontSize: 14,
      color: "#9298a3"
    }
  }));
  const classes = useStyles();

  const EmailInputField = withStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#4a4e4e",
          borderWidth: "2px",
          borderRadius: "8px"
        },
        "&:hover fieldset": {
          borderColor: "#626969"
        },
        "&.Mui-focused fieldset": {
          borderColor: "323636"
        }
      }
    }
  })(TextField);
  return (
    <>
      <div className={classes.loginContainer}>
        <div className={classes.ocadoLogo}>
          <OcadoLogo />
        </div>
        <OcadoLogoLarge width={447} altStyle={true} />
        <form onSubmit={onSubmit}>
          <div className={classes.passcodeContainer}>
            <div>
              <h4 className={classes.forget4Text}>Forgotten Password?</h4>
              <p className={classes.forgetPText}>
                Please enter your new password below:
              </p>
            </div>
            <div className={classes.formControlWrap}>
              <Typography className={classes.subtitle}>New Password</Typography>
              <EmailInputField
                fullWidth
                id="newPassword"
                type="password"
                variant="outlined"
                {...register("password", {
                  required: "New Password is required",
                  pattern: {
                    value: /^(?=.*[!@#$^*-])(?=.*[0-9])(?=.*[A-Z]).{8,20}$/i,
                    message: `Your password must be a minimum of 8 characters, including an upper
                    & lower case letter, number and special character including at
                    least one of the following: ! @ # $ ^ * -`
                  }
                })}
              />
            </div>
            {errors.password && (
              <div className={classes.updatePasswordMsg}>
                {errors.password.message}
              </div>
            )}

            <div className={classes.formControlWrap}>
              <Typography className={classes.subtitle}>
                Confirm New Password
              </Typography>
              <EmailInputField
                fullWidth
                id="confirmNewPassword"
                type="password"
                variant="outlined"
                {...register("confirm_password", {
                  required: "Confirm New Password is required",
                  validate: (value) =>
                    value === watch("password") || "Passwords don't match."
                })}
              />
            </div>

            {errors.confirm_password && (
              <div className={classes.updatePasswordMsg}>
                {errors.confirm_password.message}
              </div>
            )}

            <div className={classes.forgetLoginFooter}>
              <Link to="/login" className={classes.commonBorderBtn}>
                <BackIcon isForward={false} />
                Back
              </Link>
              <button className={classes.commonBgBtn}>
                Update
                <BackIcon isForward={true} />
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdatePassword;
