import "react-toastify/dist/ReactToastify.css";

import AppStateProvider, { useAppState } from "./state/state";
import {
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";

import App from "./App";
import { CssBaseline } from "@material-ui/core";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import LoginPage from "./pages/LoginPage/LoginPage";
import NarrationProvider from "./components/NarrationProvider/NarrationProvider";
import NavigationProvider from "./components/NavigationProvider/NavigationProvider";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import UpdatePassword from "./pages/UpdatePassword/UpdatePassword";
import VerifyEmail from "./pages/verifyEmail/VerifyEmail2";
import theme from "./theme";

const XRApp = () => {
  const { error, setError } = useAppState();

  return (
    <>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <NarrationProvider>
        <NavigationProvider>
          <App />
        </NavigationProvider>
      </NarrationProvider>
    </>
  );
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <React.StrictMode>
      <Router>
        <AppStateProvider>
          <Switch>
            <PrivateRoute exact path="/">
              <XRApp />
            </PrivateRoute>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/forget-password">
              <ForgetPassword />
            </Route>
            <Route path="/update-password/:userId/:token">
              <UpdatePassword />
            </Route>
            <Route path="/verify-email/:token">
              <VerifyEmail />
            </Route>
          </Switch>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </AppStateProvider>
      </Router>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById("root")
);
