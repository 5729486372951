/* eslint-disable @typescript-eslint/prefer-namespace-keyword */
/* eslint-disable @typescript-eslint/no-namespace */
import { createTheme } from "@material-ui/core";

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    sidebarWidth: number;
    footerHeight: number;
    headerHeight: number;
    subHeaderHeight: number;
    darkColor: string;
    narratorVideoWidthS: number;
    narratorVideoWidthM: number;
    narratorVideoWidthL: number;
    paddingSidesS: number;
    paddingSidesM: number;
    paddingSidesL: number;
  }

  interface ThemeOptions {
    sidebarWidth: number;
    footerHeight: number;
    headerHeight: number;
    subHeaderHeight: number;
    darkColor: string;
    narratorVideoWidthS: number;
    narratorVideoWidthM: number;
    narratorVideoWidthL: number;
    paddingSidesS: number;
    paddingSidesM: number;
    paddingSidesL: number;
  }
}

export default createTheme({
  footerHeight: 70,
  sidebarWidth: 500,
  headerHeight: 77,
  subHeaderHeight: 37,
  darkColor: "#161616",
  narratorVideoWidthS: 140,
  narratorVideoWidthM: 161,
  narratorVideoWidthL: 171,
  paddingSidesS: 12,
  paddingSidesM: 16,
  paddingSidesL: 40,
  props: {
    MuiLink: {
      underline: "always"
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "html, body, #root": {
          height: "100%"
        }
      }
    },
    MuiInputBase: {
      root: {
        color: "black"
      }
    },
    MuiLinearProgress: {
      bar: {
        height: "20px"
      },
      root: {
        height: "20px",
        width: "150px",
        borderRadius: "10px"
      },
      barColorPrimary: {
        color: "white",
        backgroundColor: "white"
      }
    },
    MuiButton: {
      textPrimary: {
        color: "white",
        textTransform: "none"
      }
    }
  },
  typography: {
    fontFamily: "Inter, sans-serif"
  },
  palette: {
    primary: {
      light: "#68ebff",
      main: "#00a3e0",
      dark: "#0089c3",
      contrastText: "#fff"
    },
    secondary: {
      light: "#ff5578",
      main: "#ff1a68",
      dark: "#00bbf4",
      contrastText: "#000"
    },
    text: {
      primary: "#ffffff"
    }
  },
  breakpoints: {
    values: {
      xs: 500,
      sm: 600,
      md: 800,
      lg: 1200,
      xl: 1536
    }
  }
});
