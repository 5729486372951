import { NavigationContext } from "../../components/NavigationProvider/NavigationProvider";
import { useContext } from "react";

export default function useNavigationContext() {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error("useNavigationContext must be used within a NavigationProvider");
  }
  return context;
}
