import { useCallback, useEffect, useState } from "react";
import { ApiRequestUrl} from "../../api/constants";
import { useHistory } from "react-router-dom";

export function getPasscode(): { passcode: string | null, email: string | null  } {
  const match = window.location.search.match(/passcode=(.*)&?/);
  const passcode = match ? match[1] : window.sessionStorage.getItem("passcode");
  const email = window.sessionStorage.getItem("email");

  return { passcode, email };
}

export function verifyPasscode(password: string, email: string) {
  const data = { email: email, password: password };
  return fetch(`${ApiRequestUrl.BASE}/auth/signin`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data)
  })
    .then(
      async res => {
        const jsonResponse = await res.json();
        if (res.status === 401) {
          return { isValid: false, error: jsonResponse.error?.message };
        } else if (res.status === 200) {
          localStorage.setItem("userId", jsonResponse.user._id);
          return { isValid: true, token: jsonResponse.token };
        }
        return { isValid: false };
      }
    );
}

export function verifyJWT(token: string, email: string) {
  const data = { email, token };
  return fetch(`${ApiRequestUrl.BASE}/auth/signin`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data)
  })
    .then(
      async res => {
        const jsonResponse = await res.json();
        console.log("testjson", jsonResponse);
        if (res.status === 401) {
          return { isValid: false, error: jsonResponse.error?.message };
        } else if (res.status === 200) {
          localStorage.setItem("userId", jsonResponse.user._id);
          return { isValid: true, token: jsonResponse.token };
        }
        return { isValid: false };
      }
    );
}

export default function usePasscodeAuth() {
  const history = useHistory();

  const [user, setUser] = useState<{ id: string, passcode: string, token: string } | null>(null);
  const [_isAuthReady, setIsAuthReady] = useState(false);

  useEffect(() => {
    const passcode = getPasscode();
    if (passcode && passcode.passcode && passcode.email) {
      // todo: Verify JWT amd get token
      setUser({ id: passcode.email, passcode: passcode.passcode, token: passcode.passcode || "" });
      history.replace(window.location.pathname);
      setIsAuthReady(true);
    } else {
      setIsAuthReady(false);
    }
  }, [history]);

  const signIn = useCallback((password: string, email: string) => {
    return verifyPasscode(password, email).then(verification => {
      if (verification?.isValid) {
        setUser({ id: email, passcode: password, token: verification?.token });
        window.sessionStorage.setItem("passcode", verification?.token);
        window.sessionStorage.setItem("email", email);
      } else {
        throw new Error("Invalid passcode");
      }
    });
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    window.sessionStorage.clear();
    return Promise.resolve();
  }, []);

  return { user, _isAuthReady, signIn, signOut };
}
