import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";
import {
  alpha,
  withStyles
} from "@material-ui/core/styles";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 10,
    margin: 10,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 23,
    textAlign: "center",
    padding: "10px 12px",
    height: 32,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

export const OcadoInputField = (props: InputBaseProps): JSX.Element => {
  return (
    <BootstrapInput {...props} />
  );
};