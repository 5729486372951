import { AxiosPromise, AxiosResponse } from "axios";

import { ApiRequestUrl } from "./constants";
import { client } from "./config";
import { error } from "../utils/toast";

import { IRequestError } from "../types";

const parseResult = (result: AxiosResponse): AxiosResponse["data"] => result && result.data;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onError = (Response:any): IRequestError => {
  const response = Response?.response?.data;

  if (typeof response.error === "object") {
    Object.keys(response.error).map((key: string) => (error(response.error[key])));
  } else {
    error(response.error);
  }
  return {
    status: response.statusCode,
    text: response.message
  };
};

const apiGetQuizzes = (data = {}): AxiosPromise => (
  client({
    data,
    method: "GET",
    url: `${ApiRequestUrl.GET_QUIZZES_CONTENT}en`
  })
    .then(parseResult)
    .catch(onError)
);

const apiGetSections = (data = {}): AxiosPromise => (
  client({
    data,
    method: "GET",
    url: `${ApiRequestUrl.GET_SECTIONS_CONTENT}en`
  })
    .then(parseResult)
    .catch(onError)
);

const apiGetUI = (data = {}): AxiosPromise => (
  client({
    data,
    method: "GET",
    url: `${ApiRequestUrl.GET_UI_CONTENT}en`
  })
    .then(parseResult)
    .catch(onError)
);

const apiGetUnity = (data = {}): AxiosPromise => (
  client({
    data,
    method: "GET",
    url: `${ApiRequestUrl.GET_UNITY_CONTENT}en`
  })
    .then(parseResult)
    .catch(onError)
);

const apiGetAll = (data = {}): AxiosPromise => (
  client({
    data,
    method: "GET",
    url: `${ApiRequestUrl.GET_ALL_CONTENT}`
  })
    .then(parseResult)
    .catch(onError)
);

export { apiGetQuizzes, apiGetSections, apiGetUI, apiGetUnity, apiGetAll };
