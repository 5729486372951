import {
  Button,
  LinearProgress,
  Theme,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Countdown } from "./Countdown";
import { useAppState } from "../../state/state";
import { useTranslation } from "react-i18next";

import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";

const useStyles = makeStyles((theme: Theme) => ({
  "@keyframes highlight": {
    from: {
      backgroundColor: theme.palette.primary.main
    },
    to: {
      backgroundColor: theme.palette.primary.light
    }
  },
  highlight: {
    animationName: "$highlight",
    animationDuration: "360ms",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease"
  },
  withAnimation: ({ enabled }: { enabled: boolean }) => ({
    animationPlayState: enabled ? "running" : "paused"
  }),
  container: {
    display: "flex",
    backgroundColor: "#012749",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.paddingSidesS,
      paddingRight: theme.paddingSidesS
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.paddingSidesM,
      paddingRight: theme.paddingSidesM
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.paddingSidesL,
      paddingRight: theme.paddingSidesL
    },
    height: theme.footerHeight,
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    bottom: "0",
    width: "100%"
  },
  innerContainer: {
    display: "flex",
    color: "white",
    alignItems: "center"
  },
  headerItemDesktop: {
    paddingLeft: "12px",
    paddingRight: "12px"
  },
  backButton: {
    color: "white",
    width: 148,
    height: 33,
    borderColor: "#ff1a68"
  },
  nextButton: {
    color: "white",
    width: 148,
    height: 33
  },
  progressbar: {
    borderRadius: 0,
    border: "solid 3px",
    backgroundColor: "rgba(0, 0, 0, 0)"
  },
  hideXSmall: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  headerItem: {
    paddingLeft: "20px",
    paddingRight: "20px",
    fontWeight: "bold"
  },
  hideSmall: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  hideMedium: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    [theme.breakpoints.up("lg")]: {
      display: "block"
    }
  }
}));

const Footer = (): JSX.Element => {
  const { isEnded } = useNarrationContext();
  const { t } = useTranslation("ui");
  const theme = useTheme();
  const { sections, completedSections } = useAppState();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const percentDone = () => {
    if (sections !== undefined) {
      return (completedSections.length / sections.length) * 100;
    } else {
      return 0;
    }
  };

  const {
    canNavigateBack,
    canNavigateForward,
    nextSection,
    previousSection
  } = useNavigationContext();
  const classes = useStyles({ enabled: isEnded && canNavigateForward() });

  const { currentSection, currentStage } = useNavigationContext();
  const { percentPlayed } = useNarrationContext();
  const [sectionProgress, setSectionProgress] = useState(0);

  useEffect(() => {
    const noStage = currentSection.stages.length;
    const stagesCompletePercent = (((currentStage) / noStage)) * 100;
    const percentComplete = stagesCompletePercent + (percentPlayed * 100) / noStage;

    setSectionProgress(percentComplete);
  }, [percentPlayed, currentSection, currentStage]);

  return (
    <div className={classes.container}>
      <Button
        onClick={previousSection}
        className={classes.backButton}
        variant="contained"
        color="secondary"
        disabled={!canNavigateBack()}
      >
        {t("back")}
      </Button>
      <div className={classes.innerContainer}>

        <div className={classes.hideMedium}>
          <Typography variant="body1" color="textPrimary" className={classes.headerItem}>
            {t("section_progress")}
          </Typography>
        </div>
        <div className={classes.hideMedium}>
          <LinearProgress
            className={classes.progressbar}
            variant="determinate"
            value={sectionProgress}
            color="primary"
          />
        </div>

        <div className={classes.hideSmall}>
          <Typography variant="body1" color="textPrimary" className={classes.headerItem}>
            {t("total_progress")}
          </Typography>
        </div>

        <div className={classes.hideSmall}>
          <LinearProgress
            className={classes.progressbar}
            variant="determinate"
            value={percentDone()}
            color="primary"
          />
        </div>

        <Typography variant="body1" color="textPrimary" className={classes.headerItem}>
          <span>{isMediumScreen ? t("estimated_time_left_short") : t("estimated_time_left")}</span>
        </Typography>
        <Typography variant="body1" color="textPrimary"><Countdown /></Typography>
      </div>
      <Button
        onClick={nextSection}
        className={ isEnded && canNavigateForward()
          ? `${classes.highlight} ${classes.nextButton} ${classes.withAnimation}`
          : classes.nextButton
        }
        variant="contained"
        color="secondary"
        disabled={!canNavigateForward()}
      >
        {t("next")}
      </Button>
    </div>);
};

export default Footer;
