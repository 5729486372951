interface BackIconProps {
  isForward: boolean;
}

export default function BackIcon(props: BackIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" >
      <g transform={props.isForward ? "rotate(180)" : "rotate(0)"} >
        <path stroke="#000"
          strokeLinejoin="round"
          d="M10 18c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z" />
        <path stroke="#000"
          strokeLinejoin="round"
          d="M11.5 6l-4 4 4 4" />
      </g>
    </svg>
  );
}
