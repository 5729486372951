/* eslint-disable @typescript-eslint/no-explicit-any*/
import React, { createContext, useState } from "react";

import { EVENTS } from "../../constants";
import EventEmitter from "events";

type NarrationContextType = {
 setSourceWrapper: (src: string) => void;
 setIsPlaying: (isPlaying: boolean) => void;
 toggleMute: () => void;
 pause: () => void;
 onReset: () => void;
 onReady: () => void;
 onPlay: () => void;
 onPause: () => void;
 onEnded: () => void;
 onUpdate: (e: any) => void;
 setPercentPlayed: (percent: number) => void;
 narrationEvents: EventEmitter;
 isPlaying: boolean;
 isFinished: boolean;
 isMuted: boolean;
 isReady: boolean;
 isBuffering: boolean;
 isEnded: boolean;
 source: string;
 percentPlayed: number;
 toggleNarration: () => void;
 narratorHidden: boolean;
};

export const NarrationContext = createContext<NarrationContextType>(null!);

const NarrationProvider = (props: React.PropsWithChildren<Record<string, unknown>>) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [source, setSource] = useState("");
  const [isFinished, setIsFinished] = useState(false);
  const [percentPlayed, setPercentPlayed] = useState(0);
  const [updateProgress, setUpdateProgress] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const isReady = false;
  const narrationEvents = new EventEmitter();
  const [narratorHidden, setNarratorHidden] = useState(false);

  const pause = () => {
    setIsPlaying(false);
  };

  const onReset = () => {
    narrationEvents.emit(EVENTS.NARRATION_RESET);
    setPercentPlayed(0);
    setIsFinished(false);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const onUpdate = (e: any) => {
    if (updateProgress) {
      setPercentPlayed(e.played);
    } else {
      setPercentPlayed(0);
    }
  };

  const onReady = () => {
    setIsEnded(false);
    setIsBuffering(false);
    setPercentPlayed(0);
    setIsPlaying(true);
    setTimeout(() => setUpdateProgress(true), 1000);
  };

  const onPlay = () => {
    setIsEnded(false);
    narrationEvents.emit(EVENTS.NARRATION_PLAYED);
  };

  const onPause = () => {
    narrationEvents.emit(EVENTS.NARRATION_PAUSED);
  };

  const onEnded = () => {
    setIsEnded(true);
  };

  const setSourceWrapper = (src: string) => {
    setIsBuffering(true);
    setSource(src);
    setUpdateProgress(false);
  };

  const toggleNarration = (): void => {
    setNarratorHidden(prevNarratorHidden => !prevNarratorHidden);
  };

  return (
    <NarrationContext.Provider
      value={{
        setSourceWrapper,
        pause,
        onReset,
        setIsPlaying,
        toggleMute,
        onReady,
        onPlay,
        onPause,
        onUpdate,
        onEnded,
        setPercentPlayed,
        narrationEvents,
        isMuted,
        isPlaying,
        isFinished,
        isBuffering,
        isReady,
        source,
        isEnded,
        percentPlayed,
        toggleNarration,
        narratorHidden
      }}
    >
      {props.children}
    </NarrationContext.Provider>
  );
};

export default NarrationProvider;
