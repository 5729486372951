/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function CorrectTick(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 67 67"} style={{ width: 67, height: 67 }} {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-811.000000, -751.000000) translate(441.000000, 286.000000) translate(373.000000, 468.000000)">
              <circle cx="30.5" cy="30.5" r="30.5" fill="#B1BA1D" stroke="#FFF" strokeWidth="4.136"/>
              <path fill="#FFF" fillRule="nonzero" d="M33.81 17.715h1.41c1.143 0 2.068.926 2.068 2.068v20.819c0 1.142-.925 2.068-2.067 2.068h-1.41c-1.142 0-2.068-.926-2.068-2.068V19.783c0-1.142.926-2.068 2.068-2.068z" transform="translate(34.515738, 30.192469) rotate(45.000000) translate(-34.515738, -30.192469)"/>
              <path fill="#FFF" fillRule="nonzero" d="M23.385 28.997c1.528 0 2.766 1.239 2.766 2.767v.006l-.017 7.926c-.003 1.532-1.246 2.773-2.778 2.773-1.528 0-2.767-1.24-2.767-2.767v-.006l.017-7.926c.003-1.532 1.246-2.773 2.779-2.773z" transform="translate(23.370061, 35.732913) rotate(-45.000000) translate(-23.370061, -35.732913)"/>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
