import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import { animated, useSpring } from "@react-spring/web";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";

import ActiveCircle from "../Icons/ActiveCircle";
import Circle from "../Icons/Circle";
import Collapse from "@material-ui/core/Collapse";
import React from "react";
import { Section } from "../../../server/models/section.model";
import { Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import TreeView from "@material-ui/lab/TreeView";
import theme from "../../theme";
import { useAppState } from "../../state/state";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { useTranslation } from "react-i18next";

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` }
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = withStyles(() =>
  createStyles({
    label: {
      paddingBottom: 14
    },
    iconContainer: {
      paddingLeft: 16,
      paddingRight: 14,
      paddingBottom: 14
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18
    }
  })
)((props: TreeItemProps) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const StyledHeaderTreeItem = withStyles(() =>
  createStyles({
    label: {
      fontSize: "1.1rem",
      paddingBottom: 25
    },
    iconContainer: {
      paddingRight: 14
    },
    group: {
      marginLeft: 7,
      paddingLeft: 9
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0)"
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: "rgba(0, 0, 0, 0)"
    }
  })
)((props: TreeItemProps) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles(
  createStyles({
    root: {
      color: "white",
      userSelect: "none"
    },
    container: {
      position: "fixed",
      paddingTop: 26,
      paddingLeft: 20,
      left: 0,
      top: theme.headerHeight + theme.subHeaderHeight,
      bottom: theme.footerHeight,
      backgroundColor: "#1c1c1c",
      opacity: 0.9,
      [theme.breakpoints.down("md")]: {
        width: "100%"
      },
      [theme.breakpoints.up("md")]: {
        width: 600
      },
      [theme.breakpoints.up("lg")]: {
        width: 600
      }
    }
  })
);

interface ModuleMenuProps {
  open: boolean
}

export const ModuleMenu = ({ open }: ModuleMenuProps) => {
  const { sections, completedSections } = useAppState();
  const { currentSection } = useNavigationContext();
  const { setSection } = useNavigationContext();
  const { t } = useTranslation("sections");
  const classes = useStyles();
  const onModuleClick = (s: Section) => { setSection(s); };
  const [expanded, setExpanded] = React.useState<string[]>(["0"]);
  const handleToggle = (_event: React.ChangeEvent<Record<string, unknown>>, nodeIds: string[]) => {
    const last: string[] = [];
    last.push(nodeIds[0]);
    setExpanded(last);
  };
  const getIcon = (s: Section): JSX.Element => {
    if (completedSections.includes(s.index)) {
      return <ActiveCircle />;
    } else {
      return (s.titleID === currentSection.titleID ? <ActiveCircle /> : <Circle />);
    }
  };

  const makeName = (s: Section): string => {
    return (
      s?.section +
      "." +
      s?.subSection +
      " " +
      t("section-title." + s?.titleID));
  };

  const headers = [
    ...new Set(sections?.filter(s => !s.hide).map((s) => s.section))
  ].sort((a, b) => a - b).map((s) => {
    return (
      <StyledHeaderTreeItem
        expandIcon={null}
        nodeId={s.toString()}
        key={s}
        label={s.toString() + ".0 " + t("section-group-title." + s)}
        style={{
          opacity: (s === currentSection.section || expanded.includes(s.toString())) ? 1 : 0.4
        }}
      >
        {
          sections?.map((st) => {
            return (
              (st.section === s && !st.hide)
                ? <StyledTreeItem
                  expandIcon={null}
                  endIcon={getIcon(st)}
                  nodeId={st.titleID}
                  key={st.titleID}
                  label={makeName(st)}
                  onClick={() => onModuleClick(st)}
                  style={{ color: (st.index === currentSection.index) ? "#00bbf4" : "unset" }}
                />
                : null
            );
          })
        }
      </StyledHeaderTreeItem>);
  });

  return (
    <Slide in={open} direction="right" mountOnEnter unmountOnExit>
      <div className={classes.container}>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={null}
          defaultExpandIcon={null}
          defaultEndIcon={null}
          expanded={expanded}
          onNodeToggle={handleToggle}
        >
          {headers}
        </TreeView>
      </div>
    </Slide>
  );
};

