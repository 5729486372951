import { SvgIcon, SvgIconProps } from "@material-ui/core";

import React from "react";

interface CirceShapeProps extends SvgIconProps {
  radius: number;
  styleobj: React.CSSProperties;
}

export function CircleShape(props: CirceShapeProps): JSX.Element {
  return (
    <SvgIcon
      {...props}
      style={{ ...props.styleobj, ...{ width: props.radius * 2, height: props.radius * 2 } }}
      viewBox={`0 0 ${props.radius * 2} ${props.radius * 2}`}
    >
      <g fill={props.fill} fillRule="evenodd">
        <circle cx={props.radius} cy={props.radius} r={props.radius}/>
      </g>
    </SvgIcon>
  );
}
