import { SvgIconProps } from "@material-ui/core";

/* eslint-disable max-len */
interface ShareIconProps extends SvgIconProps {
  styleobj: React.CSSProperties;
}

export default function ShareIcon(props: ShareIconProps): JSX.Element {
  return (
    <svg style={{ ...props.styleobj }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path fillRule="evenodd" stroke="#fff" strokeLinejoin="round" d="M16.97 3.03c-.03-.03-.074-.038-.112-.022L3.062 8.84c-.037.016-.061.051-.062.092-.001.04.023.077.06.094l4.28 2.024c.026.012.056.012.082.002l2.644-1.117-1.118 2.642c-.011.027-.01.057.002.084l2.024 4.28c.016.036.052.059.092.059.042 0 .078-.025.094-.062l5.832-13.796c.016-.038.007-.083-.022-.112z" clipRule="evenodd" />
      <path stroke="#fff" strokeLinejoin="round" d="M5.947 14.053L3 17M7.474 16L6 17.474M3.474 13L2 14.474" />
    </svg>
  );
}