/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress, Fade, Theme, makeStyles } from "@material-ui/core";

import { Mute } from "../Icons/Mute";
import { PlayPause } from "../Icons/PlayPause";
import ReactPlayer from "react-player/vimeo";
import { ResetVideo } from "../Icons/ResetVideo";
import clsx from "clsx";
import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  videoCircle: {
    width: theme.narratorVideoWidthL,
    height: theme.narratorVideoWidthL,
    borderRadius: theme.narratorVideoWidthL / 2,
    top: "150px",
    right: theme.paddingSidesL,
    position: "fixed",
    overflow: "hidden",
    opacity: 1,
    transition: "opacity 300ms"
  },
  withBorder: {
    borderStyle: "solid",
    borderColor: theme.darkColor,
    borderWidth: 4
  },
  innerCircle: {
    backgroundColor: "#edf4fc",
    width: theme.narratorVideoWidthL,
    height: theme.narratorVideoWidthL
  },
  reactPlayer: {
    position: "relative",
    opacity: 1,
    transition: "opacity 300ms",
    left: "-90px",
    top: "-80px"
  },
  VButton: {
    position: "absolute",
    zIndex: 1,
    cursor: "pointer",
    opacity: 0.9,
    height: "45px",
    width: "45px",
    top: "110px",
    filter: "drop-shadow(0px 1px 5px rgb(0 0 0 / .2))",
    "&:hover": {
      opacity: 1,
      filter: "drop-shadow(0px 1px 5px rgb(0 0 0 / .35))",
      top: "111px"
    }
  },
  resetVideo: {
    left: "15%"
  },
  playVideo: {
    left: "37%"
  },
  muteVideo: {
    right: "15%"
  },
  progressIndicator: {
    position: "absolute",
    zIndex: 1,
    top: 70,
    left: (theme.narratorVideoWidthL / 2) - (60 / 2) - 5
  }
}));

const VideoCircle = (): JSX.Element => {
  const {
    isMuted,
    isPlaying,
    source,
    toggleMute,
    onReady,
    onPlay,
    onPause,
    onReset,
    onUpdate,
    isBuffering,
    setIsPlaying,
    onEnded,
    isEnded,
    narratorHidden
  } = useNarrationContext();
  const { isBrightSection } = useNavigationContext();
  const classes = useStyles();
  const { t } = useTranslation("sections");
  let player: any;

  const handleReset = () => {
    onReset();
    player.seekTo(0);
  };

  const ref = (playerIn: any) => {
    player = playerIn;
  };

  return (
    <Fade in={true && !narratorHidden} timeout={500}>
      <div className={
        clsx({
          [classes.videoCircle]: true,
          [classes.withBorder]: isBrightSection()
        })
      }>
        <div className={classes.innerCircle}>
          <ResetVideo onClick={handleReset} className={clsx(classes.VButton, classes.resetVideo)} />
          <PlayPause
            playing={isPlaying}
            onClick={() => setIsPlaying(!isPlaying)}
            className={clsx(classes.VButton, classes.playVideo)}
          />
          <Mute
            muted={isMuted}
            onClick={toggleMute}
            className={clsx(classes.VButton, classes.muteVideo)}
          />
          {
            isBuffering
              ? <CircularProgress size={60} className={classes.progressIndicator} />
              : null
          }
          <ReactPlayer
            ref={ref}
            config={{
              playerOptions: {
                quality: "360p",
                dnt: true,
                maxHeight: 200
              }
            }}
            className={classes.reactPlayer}
            url={t(source)}
            controls={false}
            playing={isPlaying}
            muted={isMuted}
            volume={0.9}
            width='200%'
            height='200%'
            onReady={onReady}
            onPlay={onPlay}
            onPause={onPause}
            onProgress={onUpdate}
            onEnded={onEnded}
            progressInterval={200}
            style={{
              opacity: isEnded ? 0.2 : 1,
              transition: "opacity 1s"
            }}
          />
        </div>
      </div>
    </Fade>);
};

export default VideoCircle;
