import { Button, Grid, Theme, Typography, makeStyles } from "@material-ui/core";
import React from "react";

import { CorrectTick } from "../Icons/CorrectTick";
import { IncorrectMark } from "../Icons/IncorrectMark";
import clsx from "clsx";
import { useAppState } from "../../state/state";
import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { useTranslation } from "react-i18next";

type QBStyleProps = {
  isGrid: boolean
};

const useStyles = makeStyles<Theme, QBStyleProps>((theme: Theme) => ({
  background: {
    display: "flex",
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  questionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginRight: 55
  },
  container: {
    paddingTop: theme.headerHeight + theme.subHeaderHeight + 80,
    paddingBottom: theme.footerHeight,
    display: "flex",
    position: "relative",
    flexDirection: "row",
    color: theme.palette.primary.main,
    alignItems: "flex-start",
    justifyContent: "center",
    width: 1200,
    height: "100%"
  },
  questionNumber: {
    fontWeight: 700
  },
  question: {
    fontWeight: 700,
    margin: "auto",
    textAlign: "left",
    display: "inline-block",
    color: "#262626",
    fontSize: "2.6rem"
  },
  optionsGrid: {
    width: "100%",
    justifyContent: "space-evenly",
    marginLeft: 55
  },
  option: {
    minHeight: "99px",
    marginBottom: "26px",
    borderRadius: "10px",
    boxShadow: "-7px 8px 45px -15px rgba(38, 38, 38, 0.2)",
    border: "solid 2px rgba(241, 241, 241, 0.5)",
    backgroundColor: "#fff",
    color: "#4a4e4e",
    fontSize: "21px",
    width: 640,
    cursor: "pointer",
    fontWeight: 500,
    textAlign: "center",
    userSelect: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.light + "33"
    }
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    border: "solid 2px " + theme.palette.primary.main,
    cursor: "unset",
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  selectedWrong: {
    backgroundColor: "#80002c",
    color: "white",
    border: "solid 2px " + "#80002c",
    cursor: "unset",
    "&:hover": {
      backgroundColor: "#80002c"
    }
  },
  highlightCorrect: {
    backgroundColor: "#b1ba1d",
    color: "white",
    border: "solid 2px " + "#b1ba1d",
    cursor: "unset",
    "&:hover": {
      backgroundColor: "#b1ba1d"
    }
  },
  result: {
    display: "flex",
    height: 70,
    fontSize: "1.8rem",
    color: theme.darkColor,
    fontWeight: 700,
    marginBottom: 6,
    alignItems: "center",
    "& p": {
      marginLeft: 16
    }
  },
  infoContainer: {
    fontSize: "1.0rem",
    height: 60,
    margin: "auto",
    fontWeight: 700,
    color: theme.darkColor
  },
  submitButton: {
    width: 220,
    marginTop: 30,
    height: 50,
    borderRadius: 40,
    color: "#ffffff",
    fontSize: 21,
    textTransform: "none"
  }
}));

enum QBState {
  WAITING,
  SELECTED,
  SUBMITTED
}

export const QuestionBox = (): JSX.Element => {
  const { getQuestionFormData, questionFormData } = useAppState();
  const { currentSection } = useNavigationContext();
  const { setIsPlaying, setSourceWrapper } = useNarrationContext();
  const { t } = useTranslation("quizzes");
  const classes = useStyles({ isGrid: questionFormData?.options.length > 3 });
  const [selectedOption, setSelectedOption] = React.useState(-1);
  const [qBState, setQBState] = React.useState<QBState>(QBState.WAITING);
  const [submittedOption, setSubmittedOption] = React.useState(-1);

  React.useEffect(() => {
    setIsPlaying(true);
    getQuestionFormData(currentSection.sceneName);
    setSelectedOption(-1);
    setQBState(QBState.WAITING);
    setSubmittedOption(-1);
  }, [currentSection]);

  const onSelectOption = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, i: number) => {
    if (qBState !== QBState.SUBMITTED) {
      e.stopPropagation();
      setSelectedOption(i);
      setQBState(i >= 0 ? QBState.SELECTED : QBState.WAITING);
    }
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setSubmittedOption(selectedOption);
    setSourceWrapper(t(questionFormData.responseNarrator[selectedOption]));
    setQBState(QBState.SUBMITTED);
  };

  return (
    <div className={classes.background} onClick={(e) => onSelectOption(e, -1)}>
      {questionFormData && <div className={classes.container}>
        <div className={classes.questionContainer}>
          <Typography variant="h1" className={classes.questionNumber}>
            {questionFormData.quizNumber}
          </Typography>
          <Typography variant="h4" className={classes.question}>{t(questionFormData.question)}</Typography>
        </div>
        <div className={classes.optionsGrid}>
          <Grid container>
            {questionFormData.options.map((option, i) => <div
              className={clsx(classes.option, {
                [classes.selected]: i === selectedOption,
                [classes.selectedWrong]: i === selectedOption && i !== questionFormData.correctOption &&
                qBState === QBState.SUBMITTED,
                [classes.highlightCorrect]: i === questionFormData.correctOption &&
                qBState === QBState.SUBMITTED
              })}
              onClick={(e) => onSelectOption(e, i)}
              key={option}>
              {t(option)}
            </div>)}
          </Grid>
          <div className={classes.result}>
            {qBState === QBState.SELECTED && <Button
              onClick={(e) => onSubmit(e)}
              className={classes.submitButton}
              variant="contained"
              color="secondary">
              Submit
            </Button>}
            {qBState === QBState.SUBMITTED && <>
              {submittedOption === questionFormData.correctOption
                ? <>
                  <CorrectTick />
                  <Typography className={classes.result}>Correct!</Typography>
                </>
                : <>
                  <IncorrectMark />
                  <Typography className={classes.result}>Wrong Answer.</Typography>
                </>
              }</>
            }
          </div>
          <div className={classes.infoContainer}>
            {qBState === QBState.SUBMITTED &&
          <Typography className={classes.infoContainer} variant={"h6"}>
            {t(questionFormData.optionResponse[selectedOption])}
          </Typography>}
          </div>
        </div>

      </div>}
    </div>
  );
};
