import { Typography, makeStyles } from "@material-ui/core";

import tickIcon from "../../assets/images/tick.svg";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  textContainter: {
    maxWidth: 675,
    marginLeft: "auto",
    marginRight: "auto"
  },
  title: {
    fontSize: 148,
    fontWeight: "bold",
    color: "#fff",
    marginBottom: -30
  },
  subtitle: {
    fontSize: 42,
    fontWeight: 500,
    color: "#000"
  },
  contentContainer: {
    marginTop: 57,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto"
  },
  tickIcon: {
    width: 62,
    height: 62,
    marginRigh: "-10px"
  },
  content: {
    marginLeft: 9,
    fontSize: 28,
    fontWeight: "bold",
    color: "#fff"
  },
  circle: {
    border: "3px solid #FFF",
    borderRadius: 31,
    height: 62,
    width: 62
  },
  line: {
    height: 3,
    width: 350,
    backgroundColor: "#ffffff"
  },
  lowerTextContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: "auto"
  },
  lowerText: {
    fontSize: 28,
    fontWeight: "bold",
    color: "#000000",
    width: 340,
    marginTop: 21,
    marginLeft: 35,
    marginRight: 35
  }
}));

export const FurtherOnboardingCompleted = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("sections");

  return (
    <>
      <div className={classes.textContainter}>
        <Typography className={classes.title} align="center">{t("content.further_onboarding.welcome")}</Typography>
        <Typography className={classes.subtitle} align="right">{t("content.further_onboarding.to_ocado")}</Typography>
      </div>
      <div className={classes.contentContainer}>
        <img className={classes.tickIcon} src={tickIcon} />
        <div className={classes.line}></div>
        <div className={classes.circle}></div>
        <div className={classes.line}></div>
        <div className={classes.circle}></div>
        <div className={classes.line}></div>
        <div className={classes.circle}></div>
      </div>
      <div className={classes.lowerTextContainer}>
        <Typography
          className={classes.lowerText}
          align="center">{t("content.further_onboarding.timeline_a")}
        </Typography>
        <Typography
          className={classes.lowerText}
          align="center">{t("content.further_onboarding.timeline_b")}
        </Typography>
        <Typography
          className={classes.lowerText}
          align="center">{t("content.further_onboarding.timeline_c")}
        </Typography>
        <Typography
          className={classes.lowerText}
          align="center">{t("content.further_onboarding.timeline_d")}
        </Typography>
      </div>
    </>
  );
};
