/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function ActiveCircle(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 24, height: 24 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-24.000000, -251.000000) translate(0.000000, 188.000000) translate(25.000000, 64.000000)">
              <circle cx="11" cy="11" r="11" fill="#B1BA1D" stroke="#FFF" strokeWidth="1.492"/>
              <path fill="#FFF" fillRule="nonzero" d="M12.194 6.39h.509c.411 0 .745.333.745.745v7.508c0 .412-.334.746-.745.746h-.509c-.412 0-.746-.334-.746-.746V7.135c0-.412.334-.746.746-.746z" transform="translate(12.448299, 10.889087) rotate(45.000000) translate(-12.448299, -10.889087)"/>
              <path fill="#FFF" fillRule="nonzero" d="M8.434 10.458c.55 0 .998.447.998.998v.002l-.007 2.859c0 .552-.45 1-1.002 1-.55 0-.998-.447-.998-.998v-.002l.007-2.859c0-.553.45-1 1.002-1z" transform="translate(8.428546, 12.887280) rotate(-45.000000) translate(-8.428546, -12.887280)"/>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
