import { useEffect, useState } from "react";
import { useAppState } from "../../state/state";
import { useTranslation } from "react-i18next";

export const Countdown = (): JSX.Element => {
  const { timeRemaining } = useAppState();
  const [seconds, setSeconds] = useState(0);
  const { t } = useTranslation("ui");

  const formatString = () => {
    const time = new Date(0);
    time.setSeconds(timeRemaining);
    return `${time.getUTCHours()}${t("hour")} ${time.getUTCMinutes()}${t("minutes")}`;
  };

  useEffect(() => {
    setSeconds(timeRemaining);
  }, [timeRemaining]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => { clearInterval(interval); };
  });

  return (<>{formatString()}</>);
};
