import "./i18n/i18next-config";

import { Suspense, useEffect } from "react";

import Footer from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { I18nextProvider } from "react-i18next";
import { Loader } from "./pages/Loader/Loader";
import { PageContainer } from "./components/PageContainer/PageContainer";
import { QuestionBox } from "./components/QuestionBox/QuestionBox";
import { SceneType } from "./types";
import { XRContainer } from "./components/XRContainer/XRContainer";
import i18n from "i18next";
import { useAppState } from "./state/state";
import useNavigationContext from "./hooks/useNavigationContext/useNavigationContext";

const App = (): JSX.Element => {
  const { getSections, isFetching } = useAppState();
  const { currentSection } = useNavigationContext();

  useEffect(() => {
    getSections();
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <I18nextProvider i18n={i18n}>
        {!isFetching && <XRContainer />}
        {(isFetching || currentSection === null)
          ? <Loader />
          : <>
            <Header />
            {currentSection.sceneType === SceneType.QUIZ && <QuestionBox />}
            {currentSection.sceneType === SceneType.REACT && <PageContainer />}
            <Footer />
          </>
        }
      </I18nextProvider>
    </Suspense>
  );
};

export default App;
