/* eslint-disable camelcase */
import { AxiosPromise, AxiosResponse } from "axios";

import { ApiRequestUrl } from "./constants";
import { IRequestError } from "../types";
import { client } from "./config";
import { error } from "../utils/toast";

const parseResult = (result: AxiosResponse): AxiosResponse["data"] => result && result.data;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onError = (Response: any): IRequestError => {
  const response = Response?.response?.data;

  if (typeof response.error === "object") {
    Object.keys(response.error).map((key: string) => (error(response.error[key])));
  } else {
    error(response.error);
  }
  return {
    status: response.statusCode,
    text: response.message
  };
};

export const apiForgotPassword = (data = {}): AxiosPromise => (
  client({
    data,
    method: "POST",
    url: `${ApiRequestUrl.FORGOT_PASSWORD}`
  })
    .then(parseResult)
    .catch(onError)
);

export const apiResetPassword = (data = {userId: "", token: "", confirm_password: "", password: "" }): AxiosPromise => (
  client({
    data: { password: data.password, confirm_password: data.confirm_password },
    method: "POST",
    url: `${ApiRequestUrl.RESET_PASSWORD}${data.userId}/${data.token}`
  })
    .then(parseResult)
    .catch(onError)
);

export const apiVerifyEmail = (token:string): AxiosPromise => (
  client({
    data: {},
    method: "GET",
    url: `${ApiRequestUrl.VERIFY_EMAIL}${token}`
  })
    .then(parseResult)
    .catch(onError)
);
