// Unity events
export const UNITY = {
  NEXT_SECTION: "OnNextSubSection",
  SECTION_CHANGED: "OnSectionChange",
  STRING_REQUESTED: "OnStringRequested",
  LOADED: "loaded",
  XR_READY: "OnXRReady",
  LISTENER: "JavascriptListener",
  PLAY_VIDEO: "OnPlayReactVideo",
  RECEIVE_STRING: "OnReceiveString",
  PAUSE_NARRATOR: "OnPauseNarrator",
  SET_STAGE: "OnSetStage",
  SET_SECTION: "OnSetSection",
  NAVIGATE: "OnNavigateEvent",
  NARRATION: "OnNarrationEvent",
  HOVER: "OnHoverInteractable",
  END_HOVER: "OnEndHoverInteractable"
};

// App Events
export const EVENTS = {
  NAVIGATE_FORWARD: "OnNavigateForward",
  NAVIGATE_BACK: "OnNavigateBack",
  NARRATION_PLAYED: "play",
  NARRATION_PAUSED: "pause",
  NARRATION_RESET: "reset",
  CANVAS_CLICKED: "canvasClicked",
  LANGUAGE_CHANGED: "OnLanguageChanged"
};

// "Bright" sections
export const BRIGHT_SECTIONS = [
  "history_timeline_part_1",
  "mini_quiz_1A",
  "mini_quiz_1B",
  "mini_quiz_2A",
  "mini_quiz_2B",
  "mini_quiz_3",
  "history_timeline_part_2"
];

// Language Codes
export const LC = {
  ENGB: "enGB",
  ENUS: "enUS",
  ESP: "esp",
  FR: "fr",
  JP: "jp",
  SV: "sv"
};
