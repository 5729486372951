import { NarrationContext } from "../../components/NarrationProvider/NarrationProvider";
import { useContext } from "react";

export default function useNarrationContext() {
  const context = useContext(NarrationContext);
  if (!context) {
    throw new Error("useNarrationContext must be used within a NarrationProvider");
  }
  return context;
}
