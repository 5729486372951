/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosPromise, AxiosResponse } from "axios";

import { client, clientSame} from "./config";
import { ApiRequestUrl } from "./constants";
import { IRequestError } from "../types";

import { error } from "../utils/toast";

const parseResult = (result: AxiosResponse): AxiosResponse["data"] => result && result.data;

const onError = (Response: any): IRequestError => {
  const response = Response?.response?.data;

  if (typeof response.error === "object") {
    Object.keys(response.error).map((key: string) => (error(response.error[key])));
  } else {
    error(response.error);
  }
  return {
    status: response.statusCode,
    text: response.message
  };
};

export const apiCreateSectionCompleted = (data = {}): AxiosPromise => (
  clientSame({
    data,
    method: "POST",
    url: `${ApiRequestUrl.COMPLETED_SECTION}`
  })
    .then(parseResult)
    .catch(onError)
);

export const apiGetSectionCompleted = (userId: string | null): AxiosPromise => (
  clientSame({
    method: "GET",
    url: `${ApiRequestUrl.COMPLETED_SECTION}/${userId}`
  }).then(parseResult)
    .catch(onError)
);

interface courseProgress {
  learner: string | null,
  course: string,
  completed_sections?: Array<number>,
  total_progress?: number,
  sections?: Array<any>;
}

export const apiGetCourseProgress = (data = <courseProgress>{}): AxiosPromise => (
  client({
    data,
    method: "POST",
    url: `${ApiRequestUrl.GET_COURSE_PROGRESS}`
  })
    .then(parseResult)
    .catch(onError)
);


export const apiUpsertCourseProgress = (data = <courseProgress>({})): AxiosPromise => (
  client({
    data,
    method: "POST",
    url: `${ApiRequestUrl.COURSE_PROGRESS}`
  })
    .then(parseResult)
    .catch(onError)
);

export const apiGetSections = (data = {}): AxiosPromise => (
  client({
    data,
    method: "GET",
    url: `${ApiRequestUrl.GET_SECTIONS}`
  })
    .then(parseResult)
    .catch(onError)
);

export const apiGetQuestionFormData = (data = {}): AxiosPromise => (
  client({
    data,
    method: "GET",
    url: `${ApiRequestUrl.GET_QUESTION_FORMS}`
  })
    .then(parseResult)
    .catch(onError)
);

interface sectionProgress {
  learner: string | null,
  course: string,
  section?: string,
  progress?: number,
  last_time_spent?: number,
  section_index: number;

}
export const apiGetSectionProgress = (data = <sectionProgress>{}): AxiosPromise => (
  client({
    data,
    method: "POST",
    url: `${ApiRequestUrl.GET_SECTION_PROGRESS}`
  })
    .then(parseResult)
    .catch(onError)
);

export const apiUpsertSectionProgress = (data = <sectionProgress>{}): AxiosPromise => (
  client({
    data,
    method: "POST",
    url: `${ApiRequestUrl.SECTION_PROGRESS}`
  })
    .then(parseResult)
    .catch(onError)
);
