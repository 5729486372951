import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import CrossIcon from "../../components/Icons/Cross";
import OcadoLogo from "../../components/Icons/OcadoLogo";
import OcadoLogoImg from "../../components/Icons/OcadoLogoLarge";
import TickIcon from "../../components/Icons/TickIcon";
import { apiVerifyEmail } from "../../api/auth";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  ocadoLogo: {
    position: "absolute",
    right: 10,
    top: 25
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    zIndex: 3,
    paddingTop: 186
  },
  passcodeContainer: {
    width: 454,
    maxHeight: 335,
    padding: "37px 34px 37px",
    marginTop: 50,
    marginBottom: 35,
    border: "solid 2px #979797",
    backgroundColor: "#fff",
    zIndex: 13
  },
  icon: {
    marginRight: 17,
    maxWidth: 43,
    height: "100%"
  },
  verifyEmailText: {
    color: "#9298a3",
    fontSize: 16,
    marginTop: 0,
    marginBottom: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  formControlWrap: {
    marginBottom: 20
  },
  verifyEmailFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40
  },
  commonBorderBtn: {
    display: "flex",
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 20,
    paddingLeft: 20,
    fontSize: 18,
    color: "#13171f",
    border: "1px solid #13171f",
    borderRadius: 6,
    textDecoration: "none",
    cursor: "pointer"
  },
  commonBgBtn: {
    display: "flex",
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 20,
    paddingLeft: 20,
    fontSize: 18,
    color: "#fff",
    border: "1px solid #7241f0",
    background: "#7241f0",
    borderRadius: 6,
    textDecoration: "none",
    cursor: "pointer"
  }
}));

export default function VerifyEmail() {
  type viewParams = {
    token: string;
  };
  const [message, setMessage] = useState<Element | null | JSX.Element>(null);
  const { token } = useParams<viewParams>();
  const classes = useStyles();

  useEffect(() => {
    // TODO:
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    apiVerifyEmail(token).then((responseData: any) => {
      if (responseData.status === "success") {
        setMessage(
          <h4 className={classes.verifyEmailText}>
            <TickIcon styleobj={{
              color: "#9298a3",
              fontSize: 16,
              marginTop: 0,
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"}}
            />
            Your email has been verified.
          </h4>
        );
      } else {
        setMessage(
          <h4 className={classes.verifyEmailText}>
            <CrossIcon styleobj={{width: 32, height: 32, marginRight: 10}}>
              Your email has not been verified.</CrossIcon>
          </h4>
        );
      }
    });
  }, []);

  return (
    <Fragment>
      <div className={classes.loginContainer}>
        <div className={classes.ocadoLogo}>
          <OcadoLogo />
        </div>
        <OcadoLogoImg width={447} altStyle={false}/>
        <form>
          <div className={classes.passcodeContainer}>
            {message}

            <div className={classes.verifyEmailFooter}>
              <Link to="/login" className={classes.commonBgBtn}>
                Login
              </Link>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}