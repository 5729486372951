import { Button, Typography, makeStyles } from "@material-ui/core";

import followUp from "../../assets/images/followUp.png";
import onboardingPlan from "../../assets/images/onboardingPlan.png";
import starterWorkbook from "../../assets/images/starterWorkbook.png";
import { useTranslation } from "react-i18next";
import yourManager from "../../assets/images/yourManager.png";

const useStyles = makeStyles(() => ({
  titleContainter: {
    maxWidth: 470,
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: 50
  },
  title: {
    fontSize: 103,
    fontWeight: "bold",
    color: "#fff",
    marginBottom: -30
  },
  subtitle: {
    fontSize: 29,
    fontWeight: 500,
    color: "#000"
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row"
  },
  rowImage: {
    maxWidth: 350,
    marginLeft: 11,
    marginRight: 11
  },
  downloadButton: {
    width: 350,
    height: 43,
    marginTop: 10,
    marginRight: 11,
    marginLeft: 11,
    marginBottom: 150,
    fontSize: 12,
    fontWeight: "bold"
  }
}));

export const FurtherOnboardingSupport = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("sections");
  const onButtonClick = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <div className={classes.titleContainter}>
        <Typography
          className={classes.title}
          align="center">{t("content.further_onboarding.welcome")}
        </Typography>
        <Typography
          className={classes.subtitle}
          align="right">{t("content.further_onboarding.support_title")}
        </Typography>
      </div>
      <div className={classes.buttonRow}>
        <img className={classes.rowImage} src={followUp} />
        <img className={classes.rowImage} src={starterWorkbook} />
        <img className={classes.rowImage} src={onboardingPlan} />
        <img className={classes.rowImage} src={yourManager} />
      </div>
      <div className={classes.buttonRow}>
        <Button
          onClick={() => onButtonClick("/static/files/WelcomeToOcadoFollowUpInformation.pdf")}
          className={classes.downloadButton}
          variant="contained" color="primary">
          {t("content.further_onboarding.download")}
        </Button>
        <Button
          onClick={() => onButtonClick("/static/files/OcadoNewStarterWorkbook.pdf")}
          className={classes.downloadButton}
          variant="contained"
          color="primary">
          {t("content.further_onboarding.download")}
        </Button>
        <Button
          onClick={() => onButtonClick("/static/files/MyOnboardingPlan.pdf")}
          className={classes.downloadButton}
          variant="contained"
          color="primary">
          {t("content.further_onboarding.download")}
        </Button>
        <Button
          onClick={() => onButtonClick("/static/files/TimeWithYourManagerTemplate.pdf")}
          className={classes.downloadButton}
          variant="contained"
          color="primary">
          {t("content.further_onboarding.download")}
        </Button>
      </div>
    </>
  );
};
