import { EVENTS, UNITY } from "../../constants";
import { Theme, makeStyles } from "@material-ui/core";
import Unity, { UnityContext } from "react-unity-webgl";
import { useEffect, useState } from "react";

import { SceneType } from "../../types";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { useAppState } from "../../state/state";
import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { useTranslation } from "react-i18next";

const UNITY_CONTEXT = new UnityContext({
  loaderUrl: "unity/App.loader.js",
  dataUrl: "unity/App.data.unityweb",
  frameworkUrl: "unity/App.framework.js.unityweb",
  codeUrl: "unity/App.wasm.unityweb"
});

type XRContainerStyleProps = {
  isInteractive: boolean,
  isOpen: boolean
};

const useStyles = makeStyles<Theme, XRContainerStyleProps>((theme: Theme) => ({
  root: {
    userSelect: "none",
    height: (props: XRContainerStyleProps) => props.isOpen ? "100%" : 0,
    width: (props: XRContainerStyleProps) => props.isOpen ? "100%" : 0,
    paddingTop: (props: XRContainerStyleProps) => props.isOpen
      ? theme.headerHeight
      : 0,
    pointerEvents: (props: XRContainerStyleProps) => props.isInteractive ? "auto" : "none"
  }
}));

export const XRContainer = (): JSX.Element => {
  const {
    isXRReady,
    setXRReady,
    allowInteraction,
    setAllowInteraction,
    appEvents,
    videoSrc,
    setVideoSrc,
    setUnityLoadProgress
  } = useAppState();
  const {
    nextSection,
    currentSection,
    setCurrentStage,
    navigationEvents,
    setSectionByIndex
  } = useNavigationContext();
  const { setIsPlaying, narrationEvents } = useNarrationContext();
  const classes = useStyles({
    isInteractive: allowInteraction,
    isOpen: currentSection === null ? false : currentSection.sceneType === SceneType.UNITY_SCENE
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [hoveringInteractable, setHoveringInteractable] = useState(false);
  const { t } = useTranslation("unity");

  const onUpdateSection = () => {
    if (currentSection !== null) {
      UNITY_CONTEXT.send(UNITY.LISTENER, UNITY.SECTION_CHANGED, JSON.stringify(currentSection));
    }
  };

  const onHoverInteractable = () => {
    setHoveringInteractable(true);
  };

  const onEndHoverInteractable = () => {
    setHoveringInteractable(false);
  };

  const onNextSection = () => {
    nextSection();
  };

  const onCanvasClicked = () => {
    navigationEvents.emit(EVENTS.CANVAS_CLICKED);
  };

  const onNavigateForward = (stage: number) => {
    UNITY_CONTEXT.send(UNITY.LISTENER, UNITY.NAVIGATE, JSON.stringify({
      type: "forward",
      index: stage
    }));
  };

  const onNavigateBack = (stage: number) => {
    UNITY_CONTEXT.send(UNITY.LISTENER, UNITY.NAVIGATE, JSON.stringify({
      type: "back",
      index: stage
    }));
  };

  const onNarrationEvent = (type: string) => {
    UNITY_CONTEXT.send(UNITY.LISTENER, UNITY.NARRATION, JSON.stringify({ type: type }));
  };

  const onPlayVideo = (url: string) => {
    setIsPlaying(false);
    setAllowInteraction(false);
    setVideoSrc(url);
  };

  const onClosePlayer = () => {
    setAllowInteraction(true);
    setVideoSrc(null);
  };

  const onLanguageChanged = () => {
    UNITY_CONTEXT.send(UNITY.LISTENER, EVENTS.LANGUAGE_CHANGED);
  };

  const sendString = (key: string) => {
    UNITY_CONTEXT.send(UNITY.LISTENER, UNITY.RECEIVE_STRING, JSON.stringify({
      key: key, content: t(key)
    }));
  };

  useEffect(() => {
    onUpdateSection();
  }, [isLoaded, currentSection]);

  // Register app events
  useEffect(() => {
    appEvents.on(EVENTS.LANGUAGE_CHANGED, () => onLanguageChanged());

    return function cleanup() {
      appEvents.removeAllListeners();
    };
  }, [appEvents]);

  // Register narration events
  useEffect(() => {
    narrationEvents.on(EVENTS.NARRATION_PLAYED, () => onNarrationEvent(EVENTS.NARRATION_PLAYED));
    narrationEvents.on(EVENTS.NARRATION_PAUSED, () => onNarrationEvent(EVENTS.NARRATION_PAUSED));
    narrationEvents.on(EVENTS.NARRATION_RESET, () => onNarrationEvent(EVENTS.NARRATION_RESET));

    return function cleanup() {
      narrationEvents.removeAllListeners();
    };
  }, [narrationEvents]);

  // Register navigation events
  useEffect(() => {
    UNITY_CONTEXT.on(UNITY.NEXT_SECTION, onNextSection);
    navigationEvents.on(EVENTS.NAVIGATE_BACK, (e) => onNavigateBack(e));
    navigationEvents.on(EVENTS.NAVIGATE_FORWARD, (e) => onNavigateForward(e));
    return function cleanup() {
      navigationEvents.removeAllListeners();
    };
  }, [navigationEvents]);

  // Register event listeners
  useEffect(function() {
    UNITY_CONTEXT.on(UNITY.LOADED, () => setIsLoaded(true));
    UNITY_CONTEXT.on(UNITY.XR_READY, () => setXRReady(true));
    UNITY_CONTEXT.on(UNITY.PLAY_VIDEO, (url) => onPlayVideo(url));
    UNITY_CONTEXT.on(UNITY.STRING_REQUESTED, (key) => sendString(key));
    UNITY_CONTEXT.on(UNITY.SET_STAGE, (id) => setCurrentStage(id));
    UNITY_CONTEXT.on(UNITY.SET_SECTION, (id) => setSectionByIndex(id));
    UNITY_CONTEXT.on(UNITY.HOVER, () => onHoverInteractable());
    UNITY_CONTEXT.on(UNITY.END_HOVER, () => onEndHoverInteractable());
    UNITY_CONTEXT.on(UNITY.PAUSE_NARRATOR, () => setIsPlaying(false));
    UNITY_CONTEXT.on("progress", (progression) => setUnityLoadProgress(progression));

    return function cleanup() {
      UNITY_CONTEXT.removeAllEventListeners();
    };
  }, [isLoaded]);

  const display: string = (currentSection && isXRReady)
    ? (currentSection.sceneType === SceneType.UNITY_SCENE)
      ? "block"
      : "none"
    : "none";

  return (
    <div className={classes.root} onClick={() => onCanvasClicked()}>
      { videoSrc ? <VideoPlayer src={videoSrc} closePlayer={onClosePlayer} /> : null }
      <Unity
        style={{
          display: display,
          height: "100%",
          width: "100%",
          cursor: hoveringInteractable ? "pointer" : "default"
        }} unityContext={UNITY_CONTEXT} />
    </div>);
};
