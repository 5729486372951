import { Link, useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import BackIcon from "../../components/Icons/BackIcon";
import { Fragment } from "react";
import NotAValidIcon from "../../components/Icons/NotValidIcon";
import OcadoLogo from "../../components/Icons/OcadoLogo";
import OcadoLogoLarge from "../../components/Icons/OcadoLogoLarge";
import ShareIcon from "../../components/Icons/ShareIcon";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { apiForgotPassword } from "../../api/auth";
import { success } from "../../utils/toast";
import { useForm } from "react-hook-form";

const ForgotPassword = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ email: string; isAdmin: boolean }>({
    defaultValues: { isAdmin: false }
  });

  const onSubmit = handleSubmit(async (data: { email: string }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    apiForgotPassword(data).then((responseData: any) => {
      success("Please check your Email to update your password");
      if (responseData.status === "success") {
        history.push("/login");
      }
    });
  });

  const useStyles = makeStyles(() => ({
    errorMessage: {
      color: "red",
      display: "flex",
      alignItems: "center",
      margin: "1em 0 0.2em",
      "& svg": {
        marginRight: "0.4em"
      }
    },
    languageDropdown: {
      position: "absolute",
      left: 49,
      top: 54
    },
    ocadoLogo: {
      position: "absolute",
      right: 10,
      top: 25
    },
    loginContainer: {
      backgroundImage: "linear-gradient(to bottom, #012749 18%, #061727 100%)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      zIndex: 3,
      paddingTop: 186
    },
    logo: {
      width: 447
    },
    subtitle: {
      fontSize: 17,
      fontWeight: "bold",
      color: "#4a4e4e",
      marginBottom: 7
    },
    passcodeContainer: {
      width: 454,
      minHeight: 335,
      padding: "37px 34px 37px",
      marginTop: 50,
      marginBottom: 35,
      border: "solid 2px #979797",
      backgroundColor: "#fff",
      zIndex: 13
    },
    textBox: {
      display: "flex",
      flexDirection: "row",
      maxWidth: 456,
      marginTop: 25,
      marginBottom: 25
    },
    icon: {
      marginRight: 17,
      maxWidth: 43,
      height: "100%"
    },
    noteText: {
      color: "#4a4e4e",
      fontSize: 16
    },
    loginButton: {
      width: "100%",
      marginTop: 15,
      height: 56,
      borderRadius: 8,
      color: "#ffffff",
      fontSize: 17.5
    },
    forget4Text: {
      color: "#1b1c21",
      fontSize: 24,
      marginTop: 0,
      marginBottom: 8
    },
    forgetPText: {
      color: "#9298a3",
      fontSize: 16,
      marginTop: 0,
      marginBottom: 20
    },
    formControlWrap: {
      marginBottom: 20
    },
    forgetLoginFooter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 40
    },
    commonBorderBtn: {
      display: "flex",
      alignItems: "center",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 20,
      paddingLeft: 20,
      fontSize: 18,
      color: "#13171f",
      border: "1px solid #13171f",
      borderRadius: 6,
      textDecoration: "none",
      cursor: "pointer"
    },
    forgetBackImg: {
      marginRight: 8,
      width: 20,
      height: 20
    },
    commonBgBtn: {
      display: "flex",
      alignItems: "center",
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 20,
      paddingLeft: 20,
      fontSize: 18,
      color: "#fff",
      border: "1px solid #7241f0",
      background: "#7241f0",
      borderRadius: 6,
      textDecoration: "none",
      cursor: "pointer"
    },
    forgetErorMsg: {
      display: "flex",
      alignItems: "center",
      marginTop: 15
    },
    forgetErorMsgText: {
      fontSize: 16,
      marginLeft: 8,
      color: "#f46582"
    }
  }));
  const classes = useStyles();

  const EmailInputField = withStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#4a4e4e",
          borderWidth: "2px",
          borderRadius: "8px"
        },
        "&:hover fieldset": {
          borderColor: "#626969"
        },
        "&.Mui-focused fieldset": {
          borderColor: "323636"
        }
      }
    }
  })(TextField);
  return (
    <Fragment>
      <div className={classes.loginContainer}>
        <div className={classes.ocadoLogo}>
          <OcadoLogo />
        </div>
        <OcadoLogoLarge className={classes.logo} altStyle={false} width={400}/>
        <form onSubmit={onSubmit}>
          <div className={classes.passcodeContainer}>
            <div>
              <h4 className={classes.forget4Text}>Forgotten Password?</h4>
              <p className={classes.forgetPText}>
                Please confirm that this is your email address, and we’ll send
                you a password reset token.
              </p>
            </div>
            <div className={classes.formControlWrap}>
              <Typography className={classes.subtitle}>
                Email Address
              </Typography>
              <EmailInputField
                fullWidth
                id="input-email"
                type="email"
                variant="outlined"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: "Not a valid email address format"
                  }
                })}
              />
              {errors.email && (
                <div className={classes.forgetErorMsg}>
                  <NotAValidIcon />
                  <span className={classes.forgetErorMsgText}>
                    {errors.email.message}
                  </span>
                </div>
              )}
            </div>

            <div className={classes.forgetLoginFooter}>
              <Link to="/login" className={classes.commonBorderBtn}>
                <BackIcon isForward={false} />
                Back
              </Link>
              <button className={classes.commonBgBtn}>
                Send Token
                <ShareIcon styleobj={{marginLeft: 8 }} />
              </button>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default ForgotPassword;
