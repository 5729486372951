import { BRIGHT_SECTIONS, EVENTS } from "../../constants";
import React, { createContext, useEffect, useState } from "react";
import { apiGetCourseProgress, apiUpsertCourseProgress, apiUpsertSectionProgress } from "../../api/sections";

import { EventEmitter } from "events";
import { Section } from "../../../server/models/section.model";
import { useAppState } from "../../state/state";
import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";

type NavigationContextType = {
  nextSection(): void;
  previousSection(): void;
  canNavigateBack(): boolean;
  canNavigateForward(): boolean;
  setSection(section: Section): void;
  setSectionByIndex(index: number): void;
  setCurrentStage(stage: number): void;
  goToStart(): void;
  currentSection: Section;
  currentStage: number;
  navigationEvents: EventEmitter;
  isBrightSection(): boolean;
};

export const NavigationContext = createContext<NavigationContextType>(null!);

const NavigationProvider = (props: React.PropsWithChildren<Record<string, unknown>>) => {
  const { completedSections, setCompletedSections, sections, updateTimeRemaining } = useAppState();
  const [currentStage, setCurrentStage] = useState(0);
  const [currentSection, setCurrentSection] = useState<Section>(null!);
  const [timer, setTimer] = useState(new Date());
  const { setSourceWrapper, setPercentPlayed, percentPlayed, isEnded } =
    useNarrationContext();
  const navigationEvents = new EventEmitter();

  const setSection = async (section: Section) => {
    if (currentSection !== null && !(completedSections.includes(currentSection.index))) {
      const completed = [...completedSections, currentSection.index];
      setCompletedSections(completed);
      await apiUpsertCourseProgress({
        learner: localStorage.getItem("userId"),
        // eslint-disable-next-line camelcase
        completed_sections: completed,
        course: "61e65fce8441592b9022a9a0"
      });
    }
    setPercentPlayed(0);
    setCurrentStage(0);
    setCurrentSection(section);
    updateTimeRemaining();
    setTimer(new Date());
  };

  useEffect(() => {
    const narrationUrl = currentSection?.stages[currentStage].narratorUrl;
    if (narrationUrl !== "" && narrationUrl !== undefined && narrationUrl) {
      setSourceWrapper(narrationUrl);
    }
  }, [currentSection, currentStage]);

  useEffect(() => {
    const currentTime = new Date();
    const timeDifferenceInSecond =
      (currentTime.getTime() - timer.getTime()) / 1000;
    if ((timeDifferenceInSecond > 9 && currentSection !== null) || isEnded) {
      setTimer(currentTime);
      apiUpsertSectionProgress({
        "learner": localStorage.getItem("userId"),
        "course": "61e65fce8441592b9022a9a0",
        "section": currentSection._id,
        "section_index": currentSection.index,
        "progress": percentPlayed,
        "last_time_spent": timeDifferenceInSecond
      });
    }
  }, [currentSection, percentPlayed, isEnded]);

  const nextSection = () => {
    setPercentPlayed(0);
    if (
      currentSection!.stages!.length > 1 &&
      currentSection.useStages &&
      currentStage < currentSection!.stages!.length - 1) {
      navigationEvents.emit(EVENTS.NAVIGATE_FORWARD, currentStage + 1);
      setCurrentStage(currentStage + 1);
    } else {
      const currentIndex = sections!.indexOf(currentSection!);
      const newSection = sections![currentIndex + 1];
      if (newSection) {
        setSection(newSection);
      }
    }
  };

  const previousSection = () => {
    setPercentPlayed(0);
    if (
      currentSection!.stages!.length > 1 &&
      currentSection.useStages &&
      currentStage > 0) {
      navigationEvents.emit(EVENTS.NAVIGATE_BACK, currentStage - 1);
      setCurrentStage(currentStage - 1);
    } else {
      const currentIndex = sections!.indexOf(currentSection!);
      const newSection = sections![currentIndex - 1];
      if (newSection) {
        setSection(newSection);
      }
    }
  };

  const setSectionByIndex = (_index: number) => {
    // TODO Why is this null
    const s = sections?.find(({ index }) => index === _index);
    if (s) {
      setSection(s);
    }
  };

  const goToStart = () => {
    setCurrentStage(0);
    setCurrentSection(sections![0]);
    apiGetCourseProgress({
      learner: localStorage.getItem("userId"),
      course: "61e65fce8441592b9022a9a0"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).then((responseData: any) => {
      if (responseData.status === "success") {
        setCompletedSections(responseData.courseProgress.completed_sections);
      }
    });
  };

  // TODO:
  const canNavigateBack = () => {
    if (currentSection.index === 1) {
      return false;
    }

    if (percentPlayed < 0.003 && currentSection?.stages[currentStage].narratorUrl !== "") {
      return false;
    }

    return true;
  };

  // TODO:
  const canNavigateForward = () => {
    if (percentPlayed < 0.003 && currentSection?.stages[currentStage].narratorUrl !== "") {
      return false;
    }

    const curIndex = sections!.indexOf(currentSection);
    if (curIndex >= sections!.length - 1) {
      return false;
    }

    return true;
  };

  const isBrightSection = (): boolean => {
    return BRIGHT_SECTIONS.includes(currentSection.titleID);
  };

  return (
    <NavigationContext.Provider
      value={{
        nextSection,
        previousSection,
        canNavigateBack,
        canNavigateForward,
        setSection,
        setSectionByIndex,
        setCurrentStage,
        goToStart,
        currentSection,
        navigationEvents,
        currentStage,
        isBrightSection
      }}
    >
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
