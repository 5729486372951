import { LC } from "../constants";
import LanguageDetector from "i18next-browser-languagedetector";
import { apiGetAll } from "api/translations";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

async function run() {
  const [all] = await Promise.all([
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    apiGetAll().then((responseData: any) => {
      if (responseData.status === "success") {
        return responseData.data.translation;
      }
    })
  ]);
  return { all };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
run().then((responseData: any) => {
  const resources = responseData.all;
  i18n
    .use(LanguageDetector)
    .use(initReactI18next).init({
      lng: LC.ENGB,
      fallbackLng: LC.ENGB,
      debug: true,
      interpolation: {
        escapeValue: false
      },
      ns: ["quizzes", "sections", "ui", "unity"],
      resources
    });
});