import { Fade, makeStyles } from "@material-ui/core";

import ReactPlayer from "react-player/vimeo";

const useStyles = makeStyles(() => ({
  outterContainer: {
    height: "100%",
    width: "100%",
    zIndex: 105,
    pointerEvents: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    position: "fixed",
    top: 0,
    display: "flex",
    alignItems: "center"
  },
  videoContainer: {
    height: 540,
    width: 960,
    margin: "auto",
    verticalAlign: "middle"
  },
  videoBorder: {
    padding: "5px 5px",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "#fff",
    marginBottom: 8
  }
}));

interface VideoPlayerProps {
  src: string | null;
  closePlayer: () => void;
}

const VideoPlayer = ({ src, closePlayer }: VideoPlayerProps): JSX.Element => {
  const classes = useStyles();
  // const onClosePlayer
  return (
    <Fade in timeout={400}>
      <div className={classes.outterContainer} onClick={closePlayer}>
        <div className={classes.videoContainer}>
          <div className={classes.videoBorder}>
            <ReactPlayer
              playing={true}
              controls={true}
              className='reactPlayer'
              url={src!}
              width="100%"
              height="100%"
              pip={false}
              loop={false}
            />
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default VideoPlayer;
