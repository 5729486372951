import { Button, CircularProgress, Theme, Typography, makeStyles } from "@material-ui/core";

import { CircleShape } from "../../components/Icons/shapes/CircleShape";
import OcadoLogo from "../../components/Icons/OcadoLogo";
import OcadoLogoLarge from "../../components/Icons/OcadoLogoLarge";
import { useAppState } from "../../state/state";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    zIndex: 3,
    paddingTop: 186,
    backgroundImage: "linear-gradient(to bottom, #012749 18%, #061727 100%)"
  },
  info: {
    marginTop: 41,
    fontWeight: 500,
    fontSize: 21
  },
  ocadoLogo: {
    position: "absolute",
    right: 10,
    top: 25
  },
  startButton: {
    width: 155,
    marginTop: 50,
    height: 48,
    borderRadius: 40,
    color: "#ffffff",
    fontSize: 14
  },
  loadingBox: {
    display: "grid",
    marginTop: 30
  },
  loadingCircle: {
    gridArea: "1 / 1",
    margin: "auto",
    fill: theme.palette.secondary.main
  },
  readyCircle: {
    gridArea: "1 / 1",
    margin: "auto",
    cursor: "pointer",
    opacity: 0.94,
    "&:hover": {
      transform: "scale(1.015)",
      opacity: 1
    }
  },
  loadingCircleProgress: {
    gridArea: "1 / 1",
    margin: "auto",
    fill: theme.palette.secondary.main
  },
  loadingPercent: {
    gridArea: "1 / 1",
    color: theme.palette.secondary.main,
    margin: "auto",
    fontSize: 36,
    fontWeight: "bold",
    letterSpacing: "0.8"
  },
  readyText: {
    gridArea: "1 / 1",
    margin: "auto",
    color: theme.palette.secondary.main,
    fontWeight: 500,
    fontSize: "22px",
    cursor: "pointer",
    userSelect: "none",
    zIndex: 200,
    pointerEvents: "none"
  },
  logo: {
    width: 447,
    marginBottom: 81
  }
}));

export const Loader = (): JSX.Element => {
  const classes = useStyles();
  const { isXRReady, unityLoadProgress } = useAppState();
  const { goToStart } = useNavigationContext();
  const { t } = useTranslation("ui");
  const handleStartClick = () => {
    goToStart();
  };

  return (
    <div className={classes.loaderContainer}>
      <div className={classes.ocadoLogo}>
        <OcadoLogo />
      </div>
      <OcadoLogoLarge altStyle={false} width={500} />
      {!isXRReady
        ? <>
          <div className={classes.loadingBox}>
            <CircularProgress
              color="secondary"
              size={178.49}
              thickness={1.7}
              className={classes.loadingCircleProgress}
              variant="determinate"
              value={unityLoadProgress * 100}
            />
            <CircleShape
              className={classes.loadingCircle}
              styleobj={{
                opacity: 0.2
              }}
              radius={83}
            />
            <Typography className={classes.loadingPercent}>
              {(unityLoadProgress * 100).toFixed(0) + "%"}
            </Typography>
          </div>
          <Typography className={classes.info} align="center">
            {t("loading")}
          </Typography>
        </>
        : <>
          <div className={classes.loadingBox}>
            <CircularProgress
              color="secondary"
              size={178.49}
              thickness={1.7}
              className={classes.loadingCircleProgress}
              variant="determinate"
              value={unityLoadProgress * 100}
            />
            <Typography className={classes.readyText}>{t("loading_done")}</Typography>
          </div>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            onClick={handleStartClick}
            className={classes.startButton}
          >
            {t("click_to_start")}
          </Button>
        </>
      }
    </div>);
};
