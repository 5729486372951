export type Callback = (...args: unknown[]) => void;
export type ErrorCallback = (error: null | Error) => void;
export type FlagSvg = () => JSX.Element
export interface IRequestError { status: number, text: string }

export enum SceneType {
  UNITY_SCENE = "unityScene",
  UNITY_360 = "unity360",
  QUIZ = "quiz",
  REACT = "react"
}
