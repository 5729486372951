import { Link, Theme, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";

import { EVENTS } from "../../constants";
import { LanguageDropdown } from "../LanguageDropdown/LanguageDropdown";
import { ModuleMenu } from "../ModuleMenu/ModuleMenu";
import OcadoLogoLarge from "../Icons/OcadoLogoLarge";
import { SectionName } from "./SectionName";
import VideoCircle from "../VideoCircle/VideoCircle";
import { useAppState } from "../../state/state";
import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  outter: {
    padding: 0,
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: 2
  },
  logoContainer: {
    display: "flex",
    backgroundColor: "white",
    height: theme.headerHeight,
    zIndex: 4,
    flexDirection: "row",
    alignItems: "center",
    alignContent: "flex-start",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    paddingLeft: theme.paddingSidesL,
    paddingRight: theme.paddingSidesL
  },
  logo: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)"
  },
  linkContainerOutter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    minWidth: 310,
    maxWidth: 500,
    padding: 0,
    textAlign: "right"
  },
  linkContainer: {
    textAlign: "right",
    color: "#4d4d4d",
    fontSize: "16px",
    textDecoration: "none",
    paddingLeft: "16px",
    fontWeight: 700,
    "&:hover": {
      color: "#252525",
      fontWeight: 800,
      textShadow: "1px 1px 7px #fff, 1px 1px 7px #01274933"
    }
  }
}));

const Header = (): JSX.Element => {
  const classes = useStyles();
  const { setVideoSrc, signOut } = useAppState();
  const { setIsPlaying, narratorHidden, toggleNarration } = useNarrationContext();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [languageBarOpen, setLanguageBarOpen] = React.useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const { currentSection, navigationEvents } = useNavigationContext();
  const { t } = useTranslation("ui");

  const onLinkClicked = () => {
    setIsPlaying(false);
    setVideoSrc(t("help_video_link"));
  };

  const onSignOutClicked = () => {
    signOut?.();
  };

  const onLanguageBarOpen = () => {
    setLanguageBarOpen(true);
  };

  const onLanguageBarClosed = () => {
    setLanguageBarOpen(false);
  };

  const onToggleNarrator = () => {
    toggleNarration();
  };

  // Register navigation events
  useEffect(() => {
    navigationEvents.on(EVENTS.CANVAS_CLICKED, () => {
      setMenuOpen(false);
    });
    return function cleanup() {
      navigationEvents.removeAllListeners();
    };
  }, [navigationEvents]);

  return (
    <div className={classes.outter}>
      <div className={classes.logoContainer}>
        <LanguageDropdown
          showText={false}
          altStyle
          onMenuClose={onLanguageBarClosed}
          onMenuOpen={onLanguageBarOpen}
        />
        <div className={classes.logo}>
          <OcadoLogoLarge altStyle width={300} />
        </div>
        <div className={classes.linkContainerOutter}>
          <Link className={classes.linkContainer} href="#" onClick={onToggleNarrator}>
            {t(narratorHidden ? "show_narrator" : "hide_narrator")}
          </Link>
          <Link className={classes.linkContainer} href="#" onClick={onLinkClicked}>
            {t("need_help")}
          </Link>
          <Link className={classes.linkContainer} href="#" onClick={onSignOutClicked}>
            {t("signout")}
          </Link>
        </div>
      </div>
      <ModuleMenu open={menuOpen} />
      <SectionName
        toggleMenu={toggleMenu}
        section={currentSection}
        open={!menuOpen}
        languageBarOpen={languageBarOpen}
      />
      <VideoCircle />
    </div>);
};

export { Header };
